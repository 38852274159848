import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const Notes = () => {
    const [searchTerm, setSearchTerm] = useState(''); // 搜索词
    const [notes, setNotes] = useState([]); // 笔记列表
    const [loading, setLoading] = useState(false); // 加载状态
    const [newNote, setNewNote] = useState(''); // 新增笔记内容
    const [currentPage, setCurrentPage] = useState(1); // 当前页数
    const [totalPages, setTotalPages] = useState(1); // 总页数
    const [pageSize] = useState(10); // 每页笔记数量，默认为5
    const navigate = useNavigate();

    // 检查用户是否已登录
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login'); // 如果没有登录，重定向到登录页面
        }
    }, [navigate]);

    // 加载笔记列表（不包括搜索功能）
    const loadNotes = useCallback(async (page = 1) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/note/list?page=${page}&pageSize=${pageSize}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (response.ok) {
                setNotes(data); // 接口返回的数据直接是笔记列表
                // 假设总数为 100 条，设置总页数
                const totalNotes = 100; // 假设后端会返回总笔记数量
                setTotalPages(Math.ceil(totalNotes / pageSize));
            } else {
                console.error(data.error);
            }
        } catch (error) {
            console.error('Failed to load notes:', error);
        } finally {
            setLoading(false);
        }
    }, [pageSize]);

    // 搜索笔记（单独调用搜索接口）
    const searchNotes = async (page = 1) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`/api/note/search?page=${page}&pageSize=${pageSize}&search=${encodeURIComponent(searchTerm)}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (response.ok) {
                setNotes(data); // 更新笔记列表为搜索结果
                // 假设总数为 100 条，设置总页数
                const totalNotes = 100; // 假设后端会返回总笔记数量
                setTotalPages(Math.ceil(totalNotes / pageSize));
            } else {
                console.error(data.error);
            }
        } catch (error) {
            console.error('Failed to search notes:', error);
        } finally {
            setLoading(false);
        }
    };

    // 保存笔记（保持不变）
    const saveNote = async () => {
        if (!newNote) return;
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('/api/note/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ note: newNote }),
            });
            const result = await response.json();
            if (response.ok) {
                setNewNote(''); // 清空输入框
                loadNotes(); // 重新加载笔记
            } else {
                console.error(result.error);
            }
        } catch (error) {
            console.error('Failed to save note:', error);
        } finally {
            setLoading(false);
        }
    };

    // 处理分页
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        if (searchTerm) {
            searchNotes(newPage); // 如果有搜索词，调用搜索
        } else {
            loadNotes(newPage); // 否则加载普通笔记列表
        }
    };

    // 初始加载笔记列表
    useEffect(() => {
        loadNotes(currentPage);
    }, [currentPage, loadNotes]);

    return (
        <div className='p-4'>
            {/* 新增笔记输入框 */}
            <div className="mb-4">
                <textarea
                    placeholder="请输入笔记内容..."
                    className="w-full p-2 border border-gray-300 rounded"
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                />
                <button onClick={saveNote} className="mt-2 p-2 bg-blue-500 text-white rounded">
                    保存笔记
                </button>
            </div>

            {/* 搜索框 */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="搜索笔记..."
                    className="w-full p-2 border border-gray-300 rounded"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button onClick={() => searchNotes(1)} className="mt-2 p-2 bg-blue-500 text-white rounded">
                    搜索
                </button>
            </div>

            {/* 笔记列表 */}
            <div>
                {loading ? (
                    <p>加载中...</p>
                ) : notes.length > 0 ? (
                    <ul>
                        {notes.map((note) => (
                            <li key={note.id} className="mb-2">
                                <div className='font-semibold'>
                                    <Link to={`/note/${note.id}`}>
                                        {note.content.length > 100 ? note.content.substring(0, 100) + '...' : note.content}
                                    </Link>
                                </div>
                                <p>{new Date(note.created_at).toLocaleString()}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>没有找到笔记。</p>
                )}
            </div>

            {/* 分页按钮 */}
            <div className="mt-4">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="p-2 bg-gray-300 text-black rounded"
                >
                    上一页
                </button>
                <span className="mx-4">第 {currentPage} 页 / 共 {totalPages} 页</span>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="p-2 bg-gray-300 text-black rounded"
                >
                    下一页
                </button>
            </div>
        </div>
    );
};

export default Notes;
