import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const NoteDetail = () => {
  const { id } = useParams(); // 获取路由中的笔记 id
  const [note, setNote] = useState(null); // 存储笔记详情
  const [loading, setLoading] = useState(false); // 加载状态
  const [deleting, setDeleting] = useState(false); // 删除状态
  const [editing, setEditing] = useState(false); // 编辑状态
  const [editedContent, setEditedContent] = useState(''); // 编辑中的笔记内容
  const navigate = useNavigate();

  // 加载笔记详情
  useEffect(() => {
    const fetchNote = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`/api/note/detail?id=${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // 添加 JWT 令牌
          },
        });
        const data = await response.json();
        if (response.ok) {
          setNote(data); // 成功获取笔记详情
          setEditedContent(data.content); // 初始化编辑内容
        } else {
          console.error(data.error);
        }
      } catch (error) {
        console.error('Failed to fetch note:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNote();
  }, [id]);

  // 保存编辑后的笔记
  const saveNote = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`/api/note/edit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ id, content: editedContent }),
      });
      if (response.ok) {
        setNote((prev) => ({ ...prev, content: editedContent })); // 更新显示的内容
        setEditing(false); // 退出编辑模式
      } else {
        const result = await response.json();
        console.error('Failed to edit note:', result.error);
      }
    } catch (error) {
      console.error('Failed to edit note:', error);
    } finally {
      setLoading(false);
    }
  };

  // 删除笔记
  const deleteNote = async () => {
    if (!window.confirm('确定要删除这条笔记吗？')) {
      return;
    }

    setDeleting(true);
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('/api/note/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ id }),
      });
      if (response.ok) {
        navigate('/'); // 删除成功后重定向到笔记列表页
      } else {
        const result = await response.json();
        console.error('Failed to delete note:', result.error);
      }
    } catch (error) {
      console.error('Failed to delete note:', error);
    } finally {
      setDeleting(false);
    }
  };

  if (loading) {
    return <p>加载中...</p>;
  }

  if (!note) {
    return <p>未找到笔记。</p>;
  }

  return (
    <div className='p-4'>
      <button onClick={() => navigate('/')} className="mb-4 p-2 bg-blue-500 text-white rounded">
        返回列表
      </button>

      <h2 className="text-2xl font-bold mb-4">笔记详情</h2>
      {editing ? (
        <div>
          <textarea
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            rows={6}
          />
          <button
            onClick={saveNote}
            className="mt-4 p-2 bg-green-500 text-white rounded"
            disabled={loading}
          >
            保存
          </button>
          <button
            onClick={() => setEditing(false)}
            className="mt-4 ml-4 p-2 bg-gray-500 text-white rounded"
            disabled={loading}
          >
            取消
          </button>
        </div>
      ) : (
        <div>
          <p>{note.content}</p>
          <p className="text-gray-600">{new Date(note.created_at).toLocaleString()}</p>
          <button
            onClick={() => setEditing(true)}
            className="mt-4 p-2 bg-blue-500 text-white rounded"
          >
            编辑
          </button>
          <button
            onClick={deleteNote}
            disabled={deleting}
            className="mt-4 ml-4 p-2 bg-red-500 text-white rounded"
          >
            {deleting ? '删除中...' : '删除'}
          </button>
        </div>
      )}
    </div>
  );
};

export default NoteDetail;
