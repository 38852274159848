import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthForm = ({ onLogin }) => {
  const [isLogin, setIsLogin] = useState(true); // 控制是登录还是注册
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const url = isLogin ? '/api/user/login' : '/api/user/register';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.token);
        if (onLogin) onLogin();
        navigate('/');
      } else {
        setError(data.error || (isLogin ? '登录失败' : '注册失败'));
      }
    } catch (error) {
      setError(isLogin ? '登录请求失败' : '注册请求失败');
    }
  };

  return (
    <div className="container mx-auto p-4 max-w-sm">
      <h2 className="text-2xl mb-4 text-center">
        {isLogin ? '登录' : '注册'}
      </h2>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">用户名</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">密码</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
          />
        </div>
        {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {isLogin ? '登录' : '注册'}
          </button>
          <button
            type="button"
            onClick={() => setIsLogin(!isLogin)}
            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
          >
            {isLogin ? '没有账号？注册' : '已有账号？登录'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AuthForm;
