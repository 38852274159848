import React, { useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from '@hello-pangea/dnd';
import { v4 as uuidv4 } from 'uuid';

const Card = () => {
  const [cardGroups, setCardGroups] = useState([]);
  const [menuVisibility, setMenuVisibility] = useState({}); // 控制小菜单的显示状态

  // 切换小菜单显示状态
  const toggleMenuVisibility = (groupId, cardId = null, itemId = null) => {
    const key =
      itemId !== null
        ? `${groupId}-${cardId}-${itemId}`
        : cardId !== null
        ? `${groupId}-${cardId}`
        : `${groupId}`;
    setMenuVisibility((prevVisibility) => ({
      ...prevVisibility,
      [key]: !prevVisibility[key],
    }));
  };

  // 隐藏所有小菜单
  const hideAllMenus = () => {
    setMenuVisibility({});
  };

  // 添加卡片组，使用 prompt
  const addCardGroup = () => {
    const groupTitle = prompt('请输入卡片组标题');
    if (groupTitle && groupTitle.trim()) {
      const newGroup = { id: uuidv4(), title: groupTitle, cards: [] };
      setCardGroups([...cardGroups, newGroup]);
    }
  };

  // 编辑卡片组标题，使用 prompt
  const editCardGroupTitle = (groupId) => {
    const groupIndex = cardGroups.findIndex((group) => group.id === groupId);
    if (groupIndex !== -1) {
      const newTitle = prompt('编辑卡片组标题', cardGroups[groupIndex].title);
      if (newTitle && newTitle.trim()) {
        const updatedGroups = [...cardGroups];
        updatedGroups[groupIndex] = {
          ...updatedGroups[groupIndex],
          title: newTitle,
        };
        setCardGroups(updatedGroups);
        hideAllMenus(); // 编辑后隐藏所有菜单
      }
    }
  };

  // 删除卡片组，使用 confirm
  const removeCardGroup = (groupId) => {
    if (window.confirm('确认删除此卡片组吗？')) {
      const updatedGroups = cardGroups.filter((group) => group.id !== groupId);
      setCardGroups(updatedGroups);
      hideAllMenus(); // 删除后隐藏所有菜单
    }
  };

  // 添加卡片，使用 prompt
  const addCard = (groupId) => {
    const cardTitle = prompt('请输入卡片标题');
    if (cardTitle && cardTitle.trim()) {
      const updatedGroups = cardGroups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            cards: [
              ...group.cards,
              { id: uuidv4(), title: cardTitle, items: [] },
            ],
          };
        }
        return group;
      });
      setCardGroups(updatedGroups);
    }
  };

  // 删除卡片，使用 confirm
  const removeCard = (groupId, cardId) => {
    if (window.confirm('确认删除此卡片吗？')) {
      const updatedGroups = cardGroups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            cards: group.cards.filter((card) => card.id !== cardId),
          };
        }
        return group;
      });
      setCardGroups(updatedGroups);
      hideAllMenus(); // 删除后隐藏所有菜单
    }
  };

  // 编辑卡片标题，使用 prompt
  const editCardTitle = (groupId, cardId) => {
    const updatedGroups = cardGroups.map((group) => {
      if (group.id === groupId) {
        const updatedCards = group.cards.map((card) => {
          if (card.id === cardId) {
            const newTitle = prompt('编辑卡片标题', card.title);
            if (newTitle && newTitle.trim()) {
              return { ...card, title: newTitle };
            }
          }
          return card;
        });
        return { ...group, cards: updatedCards };
      }
      return group;
    });
    setCardGroups(updatedGroups);
    hideAllMenus(); // 编辑后隐藏所有菜单
  };

  // 添加小项，使用 prompt
  const addItemToCard = (groupId, cardId) => {
    const itemTitle = prompt('请输入小项内容');
    if (itemTitle && itemTitle.trim()) {
      const updatedGroups = cardGroups.map((group) => {
        if (group.id === groupId) {
          const updatedCards = group.cards.map((card) => {
            if (card.id === cardId) {
              return {
                ...card,
                items: [
                  ...card.items,
                  { id: uuidv4(), content: itemTitle },
                ],
              };
            }
            return card;
          });
          return { ...group, cards: updatedCards };
        }
        return group;
      });
      setCardGroups(updatedGroups);
    }
  };

  // 编辑小项，使用 prompt
  const editCardItem = (groupId, cardId, itemId) => {
    const updatedGroups = cardGroups.map((group) => {
      if (group.id === groupId) {
        const updatedCards = group.cards.map((card) => {
          if (card.id === cardId) {
            const updatedItems = card.items.map((item) => {
              if (item.id === itemId) {
                const newItemContent = prompt(
                  '编辑小项内容',
                  item.content
                );
                if (newItemContent && newItemContent.trim()) {
                  return { ...item, content: newItemContent };
                }
              }
              return item;
            });
            return { ...card, items: updatedItems };
          }
          return card;
        });
        return { ...group, cards: updatedCards };
      }
      return group;
    });
    setCardGroups(updatedGroups);
    hideAllMenus(); // 编辑后隐藏所有菜单
  };

  // 删除小项，使用 confirm
  const removeCardItem = (groupId, cardId, itemId) => {
    if (window.confirm('确认删除此小项吗？')) {
      const updatedGroups = cardGroups.map((group) => {
        if (group.id === groupId) {
          const updatedCards = group.cards.map((card) => {
            if (card.id === cardId) {
              return {
                ...card,
                items: card.items.filter((item) => item.id !== itemId),
              };
            }
            return card;
          });
          return { ...group, cards: updatedCards };
        }
        return group;
      });
      setCardGroups(updatedGroups);
      hideAllMenus(); // 删除后隐藏所有菜单
    }
  };

  // 处理拖拽结束事件
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination, type } = result;

    if (type === 'group') {
      const items = Array.from(cardGroups);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);
      setCardGroups(items);
    } else if (type === 'card') {
      const sourceGroupId = source.droppableId.split('-')[1];
      const destGroupId = destination.droppableId.split('-')[1];

      if (!sourceGroupId || !destGroupId) return;

      setCardGroups((prevGroups) => {
        const updatedGroups = [...prevGroups];

        const sourceGroupIndex = updatedGroups.findIndex(
          (group) => group.id === sourceGroupId
        );
        const destGroupIndex = updatedGroups.findIndex(
          (group) => group.id === destGroupId
        );

        if (sourceGroupIndex === -1 || destGroupIndex === -1) return prevGroups;

        const sourceCards = Array.from(
          updatedGroups[sourceGroupIndex].cards
        );
        const [movedCard] = sourceCards.splice(source.index, 1);

        if (sourceGroupId === destGroupId) {
          // 在同一组内排序
          sourceCards.splice(destination.index, 0, movedCard);
          updatedGroups[sourceGroupIndex] = {
            ...updatedGroups[sourceGroupIndex],
            cards: sourceCards,
          };
        } else {
          // 移动到不同的组
          const destCards = Array.from(
            updatedGroups[destGroupIndex].cards
          );
          destCards.splice(destination.index, 0, movedCard);

          updatedGroups[sourceGroupIndex] = {
            ...updatedGroups[sourceGroupIndex],
            cards: sourceCards,
          };
          updatedGroups[destGroupIndex] = {
            ...updatedGroups[destGroupIndex],
            cards: destCards,
          };
        }

        return updatedGroups;
      });
    } else if (type === 'item') {
      const sourceIds = source.droppableId.split('-');
      const destIds = destination.droppableId.split('-');

      const sourceGroupId = sourceIds[1];
      const sourceCardId = sourceIds[2];
      const destGroupId = destIds[1];
      const destCardId = destIds[2];

      if (!sourceGroupId || !sourceCardId || !destGroupId || !destCardId)
        return;

      setCardGroups((prevGroups) => {
        const updatedGroups = [...prevGroups];

        const sourceGroupIndex = updatedGroups.findIndex(
          (group) => group.id === sourceGroupId
        );
        const destGroupIndex = updatedGroups.findIndex(
          (group) => group.id === destGroupId
        );

        if (sourceGroupIndex === -1 || destGroupIndex === -1)
          return prevGroups;

        const sourceCardIndex = updatedGroups[
          sourceGroupIndex
        ].cards.findIndex((card) => card.id === sourceCardId);
        const destCardIndex = updatedGroups[destGroupIndex].cards.findIndex(
          (card) => card.id === destCardId
        );

        if (sourceCardIndex === -1 || destCardIndex === -1)
          return prevGroups;

        const sourceItems = Array.from(
          updatedGroups[sourceGroupIndex].cards[sourceCardIndex].items
        );
        const [movedItem] = sourceItems.splice(source.index, 1);

        if (
          sourceGroupId === destGroupId &&
          sourceCardId === destCardId
        ) {
          // 在同一卡片内排序
          sourceItems.splice(destination.index, 0, movedItem);
          updatedGroups[sourceGroupIndex].cards[sourceCardIndex] = {
            ...updatedGroups[sourceGroupIndex].cards[sourceCardIndex],
            items: sourceItems,
          };
        } else {
          // 移动到不同的卡片
          const destItems = Array.from(
            updatedGroups[destGroupIndex].cards[destCardIndex].items
          );
          destItems.splice(destination.index, 0, movedItem);

          updatedGroups[sourceGroupIndex].cards[sourceCardIndex] = {
            ...updatedGroups[sourceGroupIndex].cards[sourceCardIndex],
            items: sourceItems,
          };
          updatedGroups[destGroupIndex].cards[destCardIndex] = {
            ...updatedGroups[destGroupIndex].cards[destCardIndex],
            items: destItems,
          };
        }

        return updatedGroups;
      });
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-6">卡片工具</h1>
      <div className="mb-6">
        <button onClick={addCardGroup} className="bg-blue-500 text-white px-4 py-2 rounded">
          新增卡片组
        </button>
      </div>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="all-groups" type="group">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="space-y-6">
              {cardGroups.map((group, groupIndex) => (
                <Draggable key={group.id} draggableId={group.id} index={groupIndex}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="border rounded p-4 bg-gray-100 w-full"
                    >
                      <div className="flex justify-between items-center mb-4">
                        <div className="flex items-center">
                          {/* Drag Handle */}
                          <div
                            {...provided.dragHandleProps}
                            className="cursor-pointer mr-2 text-gray-500"
                          >
                            {/* Drag Handle Icon */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M7 4h2v2H7V4zm0 4h2v2H7V8zm0 4h2v2H7v-2zm4-8h2v2h-2V4zm0 4h2v2h-2V8zm0 4h2v2h-2v-2z" />
                            </svg>
                          </div>
                          <h2 className="text-xl font-bold">{group.title}</h2>
                        </div>
                        <div className="relative">
                          {/* 省略号图标 */}
                          <button
                            onClick={() => toggleMenuVisibility(group.id)}
                            className="text-gray-500"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 6v.01M12 12v.01M12 18v.01"
                              />
                            </svg>
                          </button>
                          {/* 小菜单 */}
                          {menuVisibility[group.id] && (
                            <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                              <button
                                onClick={() => editCardGroupTitle(group.id)}
                                className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-200"
                              >
                                编辑
                              </button>
                              <button
                                onClick={() => removeCardGroup(group.id)}
                                className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-200"
                              >
                                删除
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <Droppable droppableId={`cards-${group.id}`} type="card" direction="horizontal">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="flex flex-wrap gap-4 mb-4"
                          >
                            {group.cards.map((card, cardIndex) => (
                              <Draggable key={card.id} draggableId={card.id} index={cardIndex}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className="border rounded p-4 bg-white w-60"
                                  >
                                    <div className="flex justify-between items-center mb-4">
                                      <div className="flex items-center">
                                        {/* Drag Handle */}
                                        <div
                                          {...provided.dragHandleProps}
                                          className="cursor-pointer mr-2 text-gray-500"
                                        >
                                          {/* Drag Handle Icon */}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                          >
                                            <path d="M7 4h2v2H7V4zm0 4h2v2H7V8zm0 4h2v2H7v-2zm4-8h2v2h-2V4zm0 4h2v2h-2V8zm0 4h2v2h-2v-2z" />
                                          </svg>
                                        </div>
                                        <h3 className="text-xl font-bold">{card.title}</h3>
                                      </div>
                                      <div className="relative">
                                        {/* 省略号图标 */}
                                        <button
                                          onClick={() => toggleMenuVisibility(group.id, card.id)}
                                          className="text-gray-500"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M12 6v.01M12 12v.01M12 18v.01"
                                            />
                                          </svg>
                                        </button>
                                        {/* 小菜单 */}
                                        {menuVisibility[`${group.id}-${card.id}`] && (
                                          <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                                            <button
                                              onClick={() => editCardTitle(group.id, card.id)}
                                              className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-200"
                                            >
                                              编辑
                                            </button>
                                            <button
                                              onClick={() => removeCard(group.id, card.id)}
                                              className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-200"
                                            >
                                              删除
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <Droppable
                                      droppableId={`items-${group.id}-${card.id}`}
                                      type="item"
                                    >
                                      {(provided) => (
                                        <ul
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                          className="space-y-2"
                                        >
                                          {card.items.map((item, itemIndex) => (
                                            <Draggable
                                              key={item.id}
                                              draggableId={item.id}
                                              index={itemIndex}
                                            >
                                              {(provided) => (
                                                <li
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  className="flex justify-between items-center"
                                                >
                                                  <div className="flex items-center">
                                                    {/* Drag Handle */}
                                                    <div
                                                      {...provided.dragHandleProps}
                                                      className="cursor-pointer mr-2 text-gray-500"
                                                    >
                                                      {/* Drag Handle Icon */}
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-6 w-6"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                      >
                                                        <path d="M7 4h2v2H7V4zm0 4h2v2H7V8zm0 4h2v2H7v-2zm4-8h2v2h-2V4zm0 4h2v2h-2V8zm0 4h2v2h-2v-2z" />
                                                      </svg>
                                                    </div>
                                                    <div>{item.content}</div>
                                                  </div>
                                                  <div className="relative flex items-center">
                                                    <button
                                                      onClick={() =>
                                                        toggleMenuVisibility(group.id, card.id, item.id)
                                                      }
                                                      className="text-gray-500"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-6 w-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                      >
                                                        <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth={2}
                                                          d="M12 6v.01M12 12v.01M12 18v.01"
                                                        />
                                                      </svg>
                                                    </button>
                                                    {/* 小菜单 */}
                                                    {menuVisibility[`${group.id}-${card.id}-${item.id}`] && (
                                                      <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                                                        <button
                                                          onClick={() =>
                                                            editCardItem(group.id, card.id, item.id)
                                                          }
                                                          className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-200"
                                                        >
                                                          编辑
                                                        </button>
                                                        <button
                                                          onClick={() =>
                                                            removeCardItem(group.id, card.id, item.id)
                                                          }
                                                          className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-200"
                                                        >
                                                          删除
                                                        </button>
                                                      </div>
                                                    )}
                                                  </div>
                                                </li>
                                              )}
                                            </Draggable>
                                          ))}
                                          {provided.placeholder}
                                        </ul>
                                      )}
                                    </Droppable>

                                    <div className="mt-4">
                                      <button
                                        onClick={() => addItemToCard(group.id, card.id)}
                                        className="bg-blue-500 text-white px-4 py-2 rounded"
                                      >
                                        新增小项
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>

                      <div className="flex gap-2">
                        <button
                          onClick={() => addCard(group.id)}
                          className="bg-green-500 text-white px-4 py-2 rounded"
                        >
                          创建卡片
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default Card;
