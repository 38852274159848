import React, { useState, useEffect, useRef } from 'react'; 
import { Route, Routes, Link, useNavigate } from 'react-router-dom';
import Notes from './pages/note';
import NoteDetail from './pages/note/note';
import Card from './pages/card';

// 改为使用合并后的 AuthForm
import AuthForm from './pages/user';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // 检查登录状态
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  // 监听 localStorage 的变化（例如在其他标签页登录/登出）
  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // 点击外部区域关闭下拉菜单
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showDropdown]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setShowDropdown(false);
    navigate('/login');
  };

  const handleAvatarClick = () => {
    if (isLoggedIn) {
      setShowDropdown(!showDropdown);
    } else {
      navigate('/login');
    }
  };

  return (
    <div>
      <nav className="bg-gray-800 px-4 py-3 flex justify-between items-center">
        {/* 左侧导航链接 */}
        <ul className="flex space-x-4 text-white">
          <li>
            <Link to="/" className="hover:underline">记录</Link>
          </li>
          <li>
            <Link to="/card" className="hover:underline">卡片</Link>
          </li>
        </ul>

        {/* 右侧头像 */}
        <div className="relative" ref={dropdownRef}>
          <button onClick={handleAvatarClick} className="flex items-center focus:outline-none">
            <img
              src="https://via.placeholder.com/40"
              alt="User Avatar"
              className="w-8 h-8 rounded-full"
            />
          </button>

          {/* 下拉菜单 */}
          {isLoggedIn && showDropdown && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
              <button
                onClick={handleLogout}
                className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                退出
              </button>
            </div>
          )}
        </div>
      </nav>

      <Routes>
        {/* 笔记列表 */}
        <Route path="/" element={<Notes />} />

        {/* 笔记详情页 */}
        <Route path="/note/:id" element={<NoteDetail />} />

        {/* 卡片页面 */}
        <Route path="/card" element={<Card />} />

        {/* 登录注册合并 */}
        <Route path="/login" element={<AuthForm onLogin={() => setIsLoggedIn(true)} />} />
      </Routes>
    </div>
  );
};

export default App;
